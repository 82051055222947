import React, { useEffect, useState } from 'react'
import {Navbar, NavbarBrand, NavbarContent, NavbarItem, Button, Chip, ButtonGroup, Skeleton} from "@nextui-org/react";
import logo from '../assets/Logo.jpg'
import logobw from '../assets/ghb&w.png'
import { CiMenuBurger, CiSearch, CiShoppingCart, CiTrash, CiUser } from "react-icons/ci";
import {Tabs, Tab} from "@nextui-org/react"; 
import {Badge} from "@nextui-org/badge";
import { Progress } from "@nextui-org/react";
import { useLoaderData, useLocation, useNavigate ,Link} from 'react-router-dom';
import {Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, cn} from "@nextui-org/react";
import {GrFormAdd, GrFormSubtract, GrFormEdit} from 'react-icons/gr'
import {MdArrowRightAlt, MdOutlineArrowDropDown, MdOutlineCancel} from 'react-icons/md'
import {Input} from "@nextui-org/react";
import { RiSearch2Line } from 'react-icons/ri';
import { PiShoppingCartSimpleBold } from "react-icons/pi";
import { HiMiniBars3BottomRight, HiOutlineArrowRightOnRectangle, HiOutlineHeart, HiOutlineMapPin, HiOutlineShoppingCart, HiOutlineUser, HiOutlineUserCircle, HiOutlineWindow, HiOutlineWrench, HiShoppingCart, HiUser } from "react-icons/hi2";
import { HiMenuAlt3 } from "react-icons/hi";
import { FiMinus, FiPlus, FiTrash2 } from 'react-icons/fi';
import {Popover, PopoverTrigger, PopoverContent} from "@nextui-org/react";
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../actions/userActions';
import { removeFromCart } from '../actions/cartActions';
import CountUp from 'react-countup';
import { PRODUCT_VARIANT_COMINATION_PRICE_RESET } from '../constants/productConstants';
import { getCategories, searchProduct } from '../actions/productActions';
import { HiMiniMagnifyingGlass } from "react-icons/hi2";
import {
    Drawer,
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    DrawerFooter,
    useDisclosure,
  } from "@nextui-org/react";
import { SlLocationPin } from "react-icons/sl";
import { IoLocationOutline } from 'react-icons/io5';

const Header = () => {
    const {isOpen, onOpen, onOpenChange} = useDisclosure();

    const [navbar, setNavbar] = useState(false);

    const [search, setSearch] = useState('');

    const history = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const productCategoryList = useSelector(state => state.productCategoryList)
    const { categories } = productCategoryList

    const productSearch = useSelector(state => state.productSearch);
    const { loading: productSearchLoading , searchProducts } = productSearch;

    const cart = useSelector(state => state.cart)
    const { loading, error, cartItems } = cart 
    
    const removeFromCartHandler = (id) => {
        dispatch(removeFromCart(id))
    }

    useEffect(() => {
        dispatch({ type: PRODUCT_VARIANT_COMINATION_PRICE_RESET })
    }, [location])

    useEffect(() => {
        if (search !=''){
            dispatch(searchProduct(search))
        }
        
    }, [dispatch, search])

    useEffect(() => {
        dispatch(getCategories())
    }, [dispatch])

    const logoutHandler = () => {
        dispatch(logout())
        // Your logout logic here, e.g., clearing tokens, redirecting, etc.
    };

  return (
    <Navbar  className={
        location.pathname.startsWith('/login') || location.pathname.startsWith('/register') || location.pathname.startsWith('/forgot')?
        'h-[125px] md:h-fit w-full bg-white py-2 border-b-[1px] hidden flex-col border-black/10 px-0':
        'h-[125px] md:h-fit w-full bg-white py-2 border-b-[1px] flex flex-col border-black/10 px-0 '
    }>
        <div  className='h-fit w-full flex flex-col '>
            <div className='h-fit  w-full flex items-center justify-between gap-4'>
                <div className='flex items-center justify-center md:gap-4'>
                    
                    <Button onPress={onOpen} isIconOnly variant='light' className='p-0 md:hidden'>
                        <HiMiniBars3BottomRight  className="text-2xl" />
                    </Button>

                    <Dropdown>
                        <DropdownTrigger backdrop="blur">
                            <Button variant='flat' radius='sm' className='p-0 hidden md:flex px-4' startContent={
                            <HiMiniBars3BottomRight  className="text-2xl" />
                        }>
                            <p className='px-2 opacity-50'>Categories</p>
                        </Button>
                        </DropdownTrigger>
                        <DropdownMenu aria-label="Example with disabled actions" className='flex flex-col p-2 rounded-[4px]' disabledKeys={["edit", "delete"]}>
                            {
                                categories?
                                categories.map(i=>(
                                <DropdownItem key="new"  className='p-0' >
                                    <Link to={`/shop/${i.slug}`} className=''>
                                        <p className='text-xs p-2'>{i.name}</p>
                                    </Link>
                                </DropdownItem>
                                )):
                                ''
                            }
                            
                        
                        </DropdownMenu>
                        </Dropdown>
                    
                    
                    <Link to={'/'} className='h-16 w-16'>
                        <img src={logo} alt='' className='h-16 w-16 object-cover' />
                    </Link>
                    <div className='hidden md:flex gap-6 border-l-[1px] border-black/10 pl-6'>
                        <Link to={'/contact'} className='text-xs uppercase opacity-50 hover:opacity-100 duration-300'>
                            <p>Contact</p>
                        </Link>

                        <Link to={'/about'} className='text-xs uppercase opacity-50 hover:opacity-100 duration-300'>
                            <p>About</p>
                        </Link>


                    </div>
                </div>
                
                <div className='h-full w-fit flex items-center justify-end gap-3'>
                    
                    

                    <div className='hidden md:block relative'>
                        <Input
                        
                        radius='sm'
                        onChange={(e) => setSearch(e.target.value)} 
                        placeholder="What are you looking for?"
                        endContent={
                            <HiMiniMagnifyingGlass className="text-2xl" />
                        }
                        type="text"
                        className='w-[125px] md:w-[200px] lg:w-[250px] rounded-[8px] text-xs'
                    />
                        <div className={
                            (searchProducts && searchProducts.length <= 1) || search == "" ?
                            'absolute top-[100%] h-fit w-full py-2 hidden':
                            'absolute top-[100%] h-fit w-full py-2 visible'
                        }>
                            <div className='h-fit w-full flex flex-col p-2 bg-white rounded-[6px] border-[1px] border-black/10'>
                                {
                                    productSearchLoading?
                                    <Skeleton className='rounded-[6px]'>
                                        <Link className='h-fit max-h-[30px] w-full py-2 border-b-[1px] border-black/5 opacity-50 hover:opacity-100 duration-200'>
                                            <p className='text-xs px-3'>Product</p>
                                        </Link>
                                    </Skeleton>
                                    :
                                    searchProducts?
                                    searchProducts.map(i=>(
                                        <Link  to={`/shop/category/${i.slug}`} onClick={() => setSearch('')} className='h-fit w-full py-2 border-b-[1px] border-black/5 opacity-50 hover:opacity-100 duration-200'>
                                            <p className='text-xs px-3'>{i.name.slice(0,50)}</p>
                                        </Link>
                                    )):
                                    ''
                                }
                            </div>
                        </div>
                    </div>

                    <Drawer
                        isDismissable={false}
                        isKeyboardDismissDisabled={true}
                        isOpen={isOpen}
                        onOpenChange={onOpenChange}
                    >
                        <DrawerContent>
                        {(onClose) => (
                            <>
                            <DrawerHeader className="flex flex-col gap-1 text-lg">
                                <div className='py-2'>
                                    Categories
                                </div>
                            </DrawerHeader>
                            <DrawerBody>
                                <div  className='flex flex-col gap-2'>
                                    {
                                        categories?
                                        categories.map(i=>(
                                        <div className='relative'>
                                            <Link onClick={onClose} to={`/shop/${i.slug}`} className='w-full p-0 border-b-[1px] border-black/10 text-left flex justify-start px-4 relative z-30'>
                                                <p className='text-sm p-2'>{i.name}</p>
                                            </Link>
                                        </div>
                                        )):
                                        ''
                                    }
                                </div>
                            </DrawerBody>
                            <DrawerFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                Close
                                </Button>
                                <Button color="primary" onPress={onClose}>
                                Action
                                </Button>
                            </DrawerFooter>
                            </>
                        )}
                        </DrawerContent>
                    </Drawer>

                    <Link to={'/cart'} className='md:hidden'>
                        <Button isIconOnly variant='light'>
                            <Badge color="primary" content="5" className='text-[8px]'>
                                <HiOutlineHeart className="text-2xl" /> 
                            </Badge>
                        </Button>
                    </Link>

                    <div className='hidden md:flex'>
                        <Dropdown >
                            <DropdownTrigger className='items-center justify-center'>
                                <Button isIconOnly variant='light' className='overflow-visible flex items-center justify-center'>
                                    <Badge color="primary" content="5" className='text-[8px]'>
                                        <HiOutlineHeart className="text-2xl" /> 
                                    </Badge>
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu aria-label="Example with disabled actions" disabledKeys={["edit", "delete"]} className=''>
                                {
                                    cartItems!=0?
                                    cartItems.map(i =>(
                                        <DropdownItem key="copy" className='p-0'>
                                            <div key={i._id} className='max-w-[250px] border-b-[1px] border-black/10 flex items-center gap-4 p-3'>
                                                <div className='h-16 w-16 rounded-[8px] border-[1px] border-black/5'>
                                                    <div className='h-16 w-16 rounded-[8px] border-[1px] border-black/5'>
                                                        <img src={i.image?i.image.image:''} alt='' className='w-full h-full object-contain rounded-[8px] scale-75'/>
                                                    </div>
                                                </div>
                                                <div className='flex flex-col gap-1 text-justify'>
                                                    <p className='text-xs opacity-50'>{i.name.slice(0, 40)}... <span className='opacity-75'>  
                                                        {i.variant ? (
                                                        <>
                                                            - ({i.variant?.variant1?.name || ''} / {i.variant?.variant2?.name || ''} / {i.variant?.variant3?.name || ''})
                                                        </>
                                                        ) : null}
                                                    
                                                    </span></p>
                                                    <div className='flex justify-between items-center'>
                                                        <p className='text-xs font-bold opacity-100'>Rs {i.variant?.price || i.discount_price || i.price || ''}
                                                        x {i.qty}</p>
                                                        <Button size='sm' onClick={()=>removeFromCartHandler(i.product)} isIconOnly startContent={
                                                            <CiTrash  className='text-white'/>
                                                        } className='w-fit bg-red-500'>
                                                        </Button>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </DropdownItem>
                                    ))
                                    :
                                    <DropdownItem key="copy" className='max-w-[200px] min-w-[200px]'>Your cart is Empty!</DropdownItem>
                                }
                            
                                <DropdownItem className='p-0'>
                                    {
                                        cartItems!=0?
                                        <Link to='/cart/checkout' className='w-full'>
                                            <Button className='w-full' radius='sm' variant='solid' color='primary'>
                                                Chackout
                                            </Button>
                                        </Link>:
                                        <Link to='/cart/checkout' className='w-full'>
                                            <Button className='w-full' radius='sm' variant='solid' color='primary'>
                                                Add more
                                            </Button>
                                        </Link>
                                    }
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    
                    <Link to={'/dashboard/orderview'} className='md:hidden relative z-30'>
                        <div>
                            <Button isIconOnly variant="flat" className='relative -z-20'>
                                <HiOutlineUserCircle className="text-2xl" />
                            </Button>
                        </div>
                    </Link>

                    <div className='hidden md:flex'>
                        <Dropdown>
                            <DropdownTrigger>
                                <Button isIconOnly variant="flat">
                                    <HiOutlineUserCircle className="text-2xl" />
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu aria-label="Action event example" >
                                <DropdownItem key="new">
                                    <Link to={'/dashboard/orderview'} className='flex items-center gap-4'>
                                        <HiOutlineUserCircle className="text-xl opacity-50" />
                                        <p>Overview</p>
                                    </Link>
                                </DropdownItem> 
                                <DropdownItem key="new">
                                    <Link to={'/dashboard/orders'} className='flex items-center gap-4'>
                                        <HiOutlineShoppingCart className="text-xl opacity-50" />
                                        <p>My Orders</p>
                                    </Link>
                                </DropdownItem>
                                {/* <DropdownItem key="new">
                                    <Link to={'/dashboard/address'} className='flex items-center gap-4'>
                                        <HiOutlineMapPin  className="text-xl opacity-50" />
                                        <p>My address</p>
                                    </Link>
                                </DropdownItem>

                                <DropdownItem key="new">
                                    <Link to={'/dashboard/account'} className='flex items-center gap-4'>
                                        <HiOutlineWindow  className="text-xl opacity-50" />
                                        <p>My Account</p>
                                    </Link>
                                </DropdownItem> */}

                                <DropdownItem key="new">
                                    <Link to={'/dashboard/changePassword'} className='flex items-center gap-4'>
                                        <HiOutlineWrench  className="text-xl opacity-50" />
                                        <p>Change Password</p>
                                    </Link>
                                </DropdownItem>
                                
                                <DropdownItem onPress={()=>logoutHandler()} key="new" className='p-0 pt-2'>
                                    <div  className='flex items-center gap-4 p-2 pt-3 border-t-[1px] border-black/10 rounded-0'>
                                        <HiOutlineArrowRightOnRectangle  className="text-xl opacity-50" />
                                        <p>logout</p>
                                    </div>
                                </DropdownItem>
                                
                            </DropdownMenu>
                        </Dropdown>           
                    </div>       
                    
                </div>
            </div> 
            <div className='md:hidden relative w-full'>
                <Input
                
                    radius='sm'
                    onChange={(e) => setSearch(e.target.value)} 
                    placeholder="Search"
                    endContent={
                        <HiMiniMagnifyingGlass className="text-2xl" />
                    } 
                    type="text"
                    className='w-full rounded-[8px] text-xs'
                />
                <div className='absolute h-[100px] w-full bg-black top-12 rounded-[6px] hidden'>

                </div>
            </div>
        </div >
    </Navbar >
  )
}

export default Header


// <Navbar className='py-4'>
//         <NavbarBrand>
//             <img src={logobw} alt='' className='h-12'></img>
//         </NavbarBrand>
//         <NavbarContent>
//             <NavbarItem className='border-b-2 border-rose-600 hover:border-rose-500 duration-200'>
//                 <Link color="foreground" href="#" className='text-sm text-black'>
//                     Home
//                 </Link>
//             </NavbarItem>
//             <NavbarItem>
//                 <Link color="foreground" href="#" className='text-sm text-black'>
//                     Cart
//                 </Link>
//             </NavbarItem>
//         </NavbarContent>
//     </Navbar>