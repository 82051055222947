export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL'

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL'

export const PRODUCT_TOP_REQUEST = 'PRODUCT_TOP_REQUEST'
export const PRODUCT_TOP_SUCCESS = 'PRODUCT_TOP_SUCCESS'
export const PRODUCT_TOP_FAIL = 'PRODUCT_TOP_FAIL'

export const PRODUCT_CREATE_REVIEW_REQUEST = 'PRODUCT_CREATE_REVIEW_REQUEST'
export const PRODUCT_CREATE_REVIEW_SUCCESS = 'PRODUCT_CREATE_REVIEW_SUCCESS'
export const PRODUCT_CREATE_REVIEW_FAIL = 'PRODUCT_CREATE_REVIEW_FAIL'
export const PRODUCT_CREATE_REVIEW_RESET = 'PRODUCT_CREATE_REVIEW_RESET'

export const PRODUCT_VARIANTS_REQUEST = 'PRODUCT_VARIANTS_REQUEST'
export const PRODUCT_VARIANTS_SUCCESS = 'PRODUCT_VARIANTS_SUCCESS'
export const PRODUCT_VARIANTS_FAIL = 'PRODUCT_VARIANTS_FAIL'
export const PRODUCT_VARIANTS_RESET = 'PRODUCT_VARIANTS_RESET'

export const PRODUCT_VARIANTS_TYPE_REQUEST = 'PRODUCT_VARIANTS_TYPE_REQUEST'
export const PRODUCT_VARIANTS_TYPE_SUCCESS = 'PRODUCT_VARIANTS_TYPE_SUCCESS'
export const PRODUCT_VARIANTS_TYPE_FAIL = 'PRODUCT_VARIANTS_TYPE_FAIL'
export const PRODUCT_VARIANTS_TYPE_RESET = 'PRODUCT_VARIANTS_TYPE_RESET'

export const PRODUCT_SEARCH_REQUEST = 'PRODUCT_SEARCH_REQUEST'
export const PRODUCT_SEARCH_SUCCESS = 'PRODUCT_SEARCH_SUCCESS'
export const PRODUCT_SEARCH_FAIL = 'PRODUCT_SEARCH_FAIL'
export const PRODUCT_SEARCH_RESET = 'PRODUCT_SEARCH_RESET'

export const PRODUCT_VARIANT_COMINATION_PRICE_REQUEST = 'PRODUCT_VARIANT_COMINATION_PRICE_REQUEST'
export const PRODUCT_VARIANT_COMINATION_PRICE_SUCCESS = 'PRODUCT_VARIANT_COMINATION_PRICE_SUCCESS'
export const PRODUCT_VARIANT_COMINATION_PRICE_FAIL = 'PRODUCT_VARIANT_COMINATION_PRICE_FAIL'
export const PRODUCT_VARIANT_COMINATION_PRICE_RESET = 'PRODUCT_VARIANT_COMINATION_PRICE_RESET'

export const PRODUCT_CATEGORY_LIST_REQUEST = 'PRODUCT_CATEGORY_LIST_REQUEST'
export const PRODUCT_CATEGORY_LIST_SUCCESS = 'PRODUCT_CATEGORY_LIST_SUCCESS'
export const PRODUCT_CATEGORY_LIST_FAIL = 'PRODUCT_CATEGORY_LIST_FAIL'
export const PRODUCT_CATEGORY_LIST_RESET = 'PRODUCT_CATEGORY_LIST_RESET'