import React from 'react'
import logo from '../assets/Logo.jpg'
import { Button } from '@nextui-org/react'
import { Link } from 'react-router-dom'
import { CiFacebook, CiInstagram, CiYoutube } from "react-icons/ci";

const Footer = () => {
  return (
    <div className='h-fit w-full flex py-8 md:py-16 shadow-2xl mt-16 border-t-[1px] border-black/10 bg-white'>
      <scetion className='h-fit w-full max-w-[1024px] xl:max-w-[1200px] mx-auto px-[15px] sm:px-[25px]'>
        <div className='h-fit w-full grid grid-cols-1 md:grid-cols-2 gap-8'>
            <div className='w-full flex flex-col gap-6'>
                <Link to='/' className='flex items-center justify-center md:justify-start gap-2'>
                  <img src={logo} alt='' className='h-[150px] w-[150px] object-contain -ml-[5px]' />
                  {/* <p className='text-3xl text-[#F02E62] font-bold capitalize leading-10'>Greeting<br/> Homes</p> */}
                </Link>
                <p className='opacity-50 max-w-[500px] text-sm text-center md:text-justify'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                <div className='flex flex-row gap-4 justify-center md:justify-start'>
                  <div className='h-[50px] w-[50px] rounded-full border-1 border-black/15 flex items-center justify-center '>
                    <CiInstagram className='text-2xl' />
                  </div>
                  <div className='h-[50px] w-[50px] rounded-full border-1 border-black/15 flex items-center justify-center '>
                    <CiFacebook  className='text-2xl'/>
                  </div>
                  <div className='h-[50px] w-[50px] rounded-full border-1 border-black/15 flex items-center justify-center '>
                    <CiYoutube  className='text-2xl'/>
                  </div>
                </div>
            </div>
            <div className='w-full text-sm  grid grid-cols-2 gap-16 px-[25px]'>

              <div className='text-base flex flex-col items-center md:items-start gap-4'>
                <p className='font-bold text-lg text-center md:text-strat'>Company</p>
                <div className='opacity-75 flex flex-col gap-2'>
                  <p className='text-center md:text-start'>About us</p>
                  <p className='text-center md:text-start'>Blog</p>
                  <p className='text-center md:text-start'>Contact Us</p>
                  <p className='text-center md:text-start'>Pricing</p>
                  <p className='text-center md:text-start'>Testimonials</p>
                </div>
              </div>

              <div className='text-base flex flex-col items-center md:items-start gap-4'>
                <p className='font-bold text-lg text-center md:text-strat'>Company</p>
                <div className='opacity-75 flex flex-col gap-2'>
                  <p className='text-center md:text-start'>About us</p>
                  <p className='text-center md:text-start'>Blog</p>
                  <p className='text-center md:text-start'>Contact Us</p>
                  <p className='text-center md:text-start'>Pricing</p>
                  <p className='text-center md:text-start'>Testimonials</p>
                </div>
              </div>

            </div>
        </div>
      </scetion>
    </div>
  )
}

export default Footer