import React, { useEffect, useState } from 'react'
import Container from '../componets/Container'
import {BreadcrumbItem, Breadcrumbs, Button, Card, CardBody, CardFooter, Image, Skeleton} from "@nextui-org/react";
import { MdArrowRightAlt } from "react-icons/md";
import { FaRegStarHalfStroke, FaRegStar , FaStar} from "react-icons/fa6";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PiShoppingCartSimpleBold } from 'react-icons/pi';
import Marquee from "react-fast-marquee";
import { listProducts } from '../actions/productActions';
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import b1 from '../assets/B1.png'
import b2 from '../assets/B2.png'
import b3 from '../assets/B3.png'
import b4 from '../assets/B4.png'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { HiOutlineQrCode } from 'react-icons/hi2';
const HomeScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation()

  // Accessing productList from Redux and setting a default empty object for products to avoid errors
  const productList = useSelector(state => state.productList);
  const { loading, products } = productList;

  // Local state to store grouped products by category
  const [groupedProducts, setGroupedProducts] = useState({});

  useEffect(() => {
    // Dispatch action to load products
    dispatch(listProducts('all', 'all'));
  }, [dispatch]);

  useEffect(() => {
    window.scroll(0,0);
  }, [location]);

  useEffect(() => {
    // Check if products exist and group them by category
    if (products && products.products) {
      const groupProductsByCategory = (productsArray) => {
        if (!Array.isArray(productsArray)) return {};
        return productsArray.reduce((acc, product) => {
          const categoryName = product?.category?.name || 'Uncategorized';
          const categorySlug = product?.category?.slug || 'uncategorized';
  
          // Initialize the category if it doesn't exist in the accumulator
          if (!acc[categorySlug]) {
            acc[categorySlug] = {
              name: categoryName,
              slug: categorySlug,
              products: []
            };
          }
  
          // Add the product to the category's product list
          acc[categorySlug].products.push(product);
  
          return acc;
        }, {});
      };
  
      setGroupedProducts(groupProductsByCategory(products.products));
    }
  }, [products]);
  



  return (
    <div className=' flex flex-col'>
      <div className='h-fit w-full px-[25px] max-w-[1024px] mx-auto py-6'>
        <Breadcrumbs>
          <BreadcrumbItem>Home</BreadcrumbItem>
        </Breadcrumbs>
      </div>

      <section className='h-fit w-full bg-white py-12'>
        <div className='h-fit w-full max-w-[1100px] mx-auto px-6 sm:px-8 lg:px-16 flex flex-col gap-8'>
            <div className='flex items-center justify-between gap-2'>
              <div className='flex flex-col gap-4'>
                <p className='text-3xl md:text-4xl font-black text-[#F02E62]'>Tranding</p>
                <div className='flex gap-2 max-w-[400px]'>
                  <div className='min-h-full w-6 rounded-[4px] bg-[#54C1C4]'>

                  </div>
                  <p className='text-xs md:text-sm'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>
              </div>
              {/* <Button className="text-base bg-[#54C1C4] text-black pointer-events-none flex-shrink-0" isIconOnly startContent={
                  <HiOutlineQrCode className='text-2xl text-white'/>
                }>
              </Button> */}
            </div>
            {
              loading?
              <div className='grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-4 '>
                <div  className='h-fit w-full flex flex-col rounded-[16px] bg-gray-50 p-2'>
                  <div className='h-[150px] w-full rounded-[8px] overflow-hidden bg-white relative'>
                    <Skeleton className='rounded-[4px]'>
                      <Link className='h-[150px]' to={'/shop/cat/product'}>
                        <img src='https://static-01.daraz.lk/p/4dbbe6982c89675213a27ca6f2968d77.jpg_400x400q75.jpg_.webp' alt='' className='h-full w-full object-contain rounded-[4px] hover:scale-105 duration-300 relative '/>
                      </Link>
                    </Skeleton>
                    <Button size='sm' className="text-base border-[2px] border-[#54C1C4] border-opacity-10 hover:border-opacity-100 bg-transparent focus:bg-[#54C1C4] hover:bg-[#54C1C4] absolute z-20 top-2 right-2 text-[#54C1C4] focus:text-black hover:text-black" isIconOnly startContent={
                        <PiShoppingCartSimpleBold className='text-xs'/>
                    }>
                    </Button>
                  </div>
                  <Link to={'/shop/cat/product'} className='flex flex-col pt-3 gap-2'>
                    

                    <div className='flex flex-col gap-1'>
                      <Skeleton className='rounded-[6px]'>
                        <p className='h-[50px] border-b-[1px] border-black/5 flex text-xs font-medium '>Product name </p>
                      </Skeleton>
                      <Skeleton className='rounded-[6px]'>
                        <p className='text-[10px] font-medium opacity-50'>/10kg /20kg</p>  
                      </Skeleton>
                    </div>

  
                    <div className='flex flex-col'>
                      <div className='flex flex-wrap items-center'>
                        <Skeleton className='rounded-[6px]'>
                          <p className='text-sm font-bold '>Rs 5,999.00</p> 
                        </Skeleton>
                        {/* <s className='text-[10px] font-medium opacity-50'>Rs 6,999.00</s> */}
                      </div>
                      <div className='flex items-center gap-2'>
                        <div className='flex gap-1'>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                        </div>
                        <Skeleton className='rounded-[6px] opacity-0'>
                          <p className='text-sm opacity-50'>(3)</p>
                        </Skeleton>
                      </div>
                    </div>
                  </Link>
                </div>   
                <div  className='h-fit w-full flex flex-col rounded-[16px] bg-gray-50 p-2'>
                  <div className='h-[150px] w-full rounded-[8px] overflow-hidden bg-white relative'>
                    <Skeleton className='rounded-[4px]'>
                      <Link className='h-[150px]' to={'/shop/cat/product'}>
                        <img src='https://static-01.daraz.lk/p/4dbbe6982c89675213a27ca6f2968d77.jpg_400x400q75.jpg_.webp' alt='' className='h-full w-full object-contain rounded-[4px] hover:scale-105 duration-300 relative '/>
                      </Link>
                    </Skeleton>
                    <Button size='sm' className="text-base border-[2px] border-[#54C1C4] border-opacity-10 hover:border-opacity-100 bg-transparent focus:bg-[#54C1C4] hover:bg-[#54C1C4] absolute z-20 top-2 right-2 text-[#54C1C4] focus:text-black hover:text-black" isIconOnly startContent={
                        <PiShoppingCartSimpleBold className='text-xs'/>
                    }>
                    </Button>
                  </div>
                  <Link to={'/shop/cat/product'} className='flex flex-col pt-3 gap-2'>
                    

                    <div className='flex flex-col gap-1'>
                      <Skeleton className='rounded-[6px]'>
                        <p className='h-[50px] border-b-[1px] border-black/5 flex text-xs font-medium '>Product name </p>
                      </Skeleton>
                      <Skeleton className='rounded-[6px]'>
                        <p className='text-[10px] font-medium opacity-50'>/10kg /20kg</p>  
                      </Skeleton>
                    </div>

  
                    <div className='flex flex-col'>
                      <div className='flex flex-wrap items-center'>
                        <Skeleton className='rounded-[6px]'>
                          <p className='text-sm font-bold '>Rs 5,999.00</p> 
                        </Skeleton>
                        {/* <s className='text-[10px] font-medium opacity-50'>Rs 6,999.00</s> */}
                      </div>
                      <div className='flex items-center gap-2'>
                        <div className='flex gap-1'>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                        </div>
                        <Skeleton className='rounded-[6px] opacity-0'>
                          <p className='text-sm opacity-50'>(3)</p>
                        </Skeleton>
                      </div>
                    </div>
                  </Link>
                </div>   
                <div  className='h-fit w-full flex flex-col rounded-[16px] bg-gray-50 p-2'>
                  <div className='h-[150px] w-full rounded-[8px] overflow-hidden bg-white relative'>
                    <Skeleton className='rounded-[4px]'>
                      <Link className='h-[150px]' to={'/shop/cat/product'}>
                        <img src='https://static-01.daraz.lk/p/4dbbe6982c89675213a27ca6f2968d77.jpg_400x400q75.jpg_.webp' alt='' className='h-full w-full object-contain rounded-[4px] hover:scale-105 duration-300 relative '/>
                      </Link>
                    </Skeleton>
                    <Button size='sm' className="text-base border-[2px] border-[#54C1C4] border-opacity-10 hover:border-opacity-100 bg-transparent focus:bg-[#54C1C4] hover:bg-[#54C1C4] absolute z-20 top-2 right-2 text-[#54C1C4] focus:text-black hover:text-black" isIconOnly startContent={
                        <PiShoppingCartSimpleBold className='text-xs'/>
                    }>
                    </Button>
                  </div>
                  <Link to={'/shop/cat/product'} className='flex flex-col pt-3 gap-2'>
                    

                    <div className='flex flex-col gap-1'>
                      <Skeleton className='rounded-[6px]'>
                        <p className='h-[50px] border-b-[1px] border-black/5 flex text-xs font-medium '>Product name </p>
                      </Skeleton>
                      <Skeleton className='rounded-[6px]'>
                        <p className='text-[10px] font-medium opacity-50'>/10kg /20kg</p>  
                      </Skeleton>
                    </div>

  
                    <div className='flex flex-col'>
                      <div className='flex flex-wrap items-center'>
                        <Skeleton className='rounded-[6px]'>
                          <p className='text-sm font-bold '>Rs 5,999.00</p> 
                        </Skeleton>
                        {/* <s className='text-[10px] font-medium opacity-50'>Rs 6,999.00</s> */}
                      </div>
                      <div className='flex items-center gap-2'>
                        <div className='flex gap-1'>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                        </div>
                        <Skeleton className='rounded-[6px] opacity-0'>
                          <p className='text-sm opacity-50'>(3)</p>
                        </Skeleton>
                      </div>
                    </div>
                  </Link>
                </div>           
                <div  className='h-fit w-full flex flex-col rounded-[16px] bg-gray-50 p-2'>
                  <div className='h-[150px] w-full rounded-[8px] overflow-hidden bg-white relative'>
                    <Skeleton className='rounded-[4px]'>
                      <Link className='h-[150px]' to={'/shop/cat/product'}>
                        <img src='https://static-01.daraz.lk/p/4dbbe6982c89675213a27ca6f2968d77.jpg_400x400q75.jpg_.webp' alt='' className='h-full w-full object-contain rounded-[4px] hover:scale-105 duration-300 relative '/>
                      </Link>
                    </Skeleton>
                    <Button size='sm' className="text-base border-[2px] border-[#54C1C4] border-opacity-10 hover:border-opacity-100 bg-transparent focus:bg-[#54C1C4] hover:bg-[#54C1C4] absolute z-20 top-2 right-2 text-[#54C1C4] focus:text-black hover:text-black" isIconOnly startContent={
                        <PiShoppingCartSimpleBold className='text-xs'/>
                    }>
                    </Button>
                  </div>
                  <Link to={'/shop/cat/product'} className='flex flex-col pt-3 gap-2'>
                    

                    <div className='flex flex-col gap-1'>
                      <Skeleton className='rounded-[6px]'>
                        <p className='h-[50px] border-b-[1px] border-black/5 flex text-xs font-medium '>Product name </p>
                      </Skeleton>
                      <Skeleton className='rounded-[6px]'>
                        <p className='text-[10px] font-medium opacity-50'>/10kg /20kg</p>  
                      </Skeleton>
                    </div>

  
                    <div className='flex flex-col'>
                      <div className='flex flex-wrap items-center'>
                        <Skeleton className='rounded-[6px]'>
                          <p className='text-sm font-bold '>Rs 5,999.00</p> 
                        </Skeleton>
                        {/* <s className='text-[10px] font-medium opacity-50'>Rs 6,999.00</s> */}
                      </div>
                      <div className='flex items-center gap-2'>
                        <div className='flex gap-1'>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                        </div>
                        <Skeleton className='rounded-[6px] opacity-0'>
                          <p className='text-sm opacity-50'>(3)</p>
                        </Skeleton>
                      </div>
                    </div>
                  </Link>
                </div>           
              </div>:
              products && products.products  ?
              <div className='grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-4 '>
                  {
                    products.products.filter(i => i.top_products == true).slice(0 , 4).map(i=>(
                      <div key={i._id} className='h-fit w-full flex flex-col rounded-[16px] bg-gray-50 p-2'>
                        <div className='h-[150px] w-full rounded-[8px] overflow-hidden bg-white relative'>
                          <Link to={`/shop/category/${i.slug}`} className='h-[150px]'>
                            <img src={
                              i.images[0].image 
                            } alt='' className='h-full w-full object-contain rounded-[4px] hover:scale-105 duration-300 relative '/>
                          </Link>
                          <div className='absolute z-20 top-2 right-0 text-white bg-[#F02E62] px-4 py-2'>
                            <p className='text-[10px]'>43%</p>
                          </div>
                        </div>
                        <Link to={`/shop/category/${i.slug}`} className='flex flex-col pt-3 gap-2'>
                          

                          <div className='flex flex-col gap-1'>
                            <p className='h-[50px] border-b-[1px] border-black/5 text-xs font-medium hidden md:flex'>{i.name.slice(0, 75)}</p>
                            <p className='h-[50px] border-b-[1px] border-black/5 flex text-xs font-medium md:hidden'>{i.name.slice(0, 75)}</p>
                          </div>

                          <div className='flex flex-col gap-2'>
                            <div className='flex flex-wrap items-center'>
                              <p className='text-base font-bold text-[#F02E62]'>Rs {i.discount_price? i.discount_price:i.price}</p> 
                              <s className='text-[10px] font-medium opacity-50'>Rs {i.price}</s>
                            </div>
                            <div className='flex items-center gap-2'>
                            <div className='flex gap-1'>
                              {[...Array(5)].map((_, index) => (
                                <FaStar
                                  key={index}
                                  className={`text-sm ${index < i.rating ? 'text-yellow-400' : 'text-gray-300'}`}
                                />
                              ))}
                            </div>
                            <p className='text-sm opacity-50'>({i.numReviews})</p>
                          </div>
                          </div>
                        </Link>
                      </div>
                    ))
                  }           
              </div>:
              ''
            }
        </div>
      </section>

      <section className='h-fit w-full bg-white'>
        <div className='h-fit w-full max-w-[1024px] mx-auto px-6 flex flex-col gap-12'>
          {loading ? (
            ''
          ) : (
            Object.keys(groupedProducts).length > 0 ? (
              Object.entries(groupedProducts).map(([categorySlug, categoryData]) => (
                <div key={categoryData} className='flex flex-col gap-8'>
                  {/* <div className='flex items-center justify-between'>
                    <p className='text-4xl font-black'>{categoryData.name}</p>
                    <Link to={`/shop/${categorySlug}`}>
                      <Button className="text-base bg-[#54C1C4] text-black pointer-events-none flex-shrink-0" isIconOnly startContent={
                          <MdArrowRightAlt className='text-2xl'/>
                        }>
                      </Button>
                    </Link> 
                  </div> */}

                  <Link to={`/shop/${categorySlug}`} className='flex flex-col gap-4'>
                    <p className='text-3xl md:text-4xl font-black text-[#F02E62]'>{categoryData.name}</p>
                    <div className='flex gap-2 max-w-[400px]'>
                      <div className='min-h-full w-6 rounded-[4px] bg-[#54C1C4]'>

                      </div>
                      <p className='text-xs md:text-sm'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </div>
                  </Link>

                  <div className='grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-4'>
                    {groupedProducts[categorySlug]?.products?.slice(0, 4).map((i) => (
                      <div key={i._id} className='h-fit w-full flex flex-col rounded-[16px] bg-gray-50 p-2'>
                        <div className='h-[150px] w-full rounded-[8px] overflow-hidden bg-white relative'>
                          <Link to={`/shop/category/${i.slug}`} className='h-[150px]'>
                            <img src={
                              i?.images?.[0]?.image || '' 
                            } alt='' className='h-full w-full object-contain rounded-[4px] hover:scale-105 duration-300 relative '/>
                          </Link>
                          <div className='absolute z-20 top-2 right-0 text-white bg-[#F02E62] px-4 py-2'>
                            <p className='text-[10px]'>43%</p>
                          </div>
                        </div>
                        <Link to={`/shop/category/${i.slug}`} className='flex flex-col pt-3 gap-2'>
                          

                          <div className='flex flex-col gap-1'>
                            <p className='h-[50px] border-b-[1px] border-black/5 text-xs font-medium hidden md:flex'>{i.name.slice(0, 85)}</p>
                            <p className='h-[50px] border-b-[1px] border-black/5 flex text-xs font-medium md:hidden'>{i.name.slice(0, 50)}...</p>
                          </div>

                
        
                          <div className='flex flex-col gap-2'>
                            <div className='flex flex-wrap items-center'>
                              <p className='text-base font-bold text-[#F02E62]'>Rs {i.discount_price? i.discount_price:i.price}</p> 
                              <s className='text-[10px] font-medium opacity-50'>Rs {i.price}</s>
                            </div>
                            <div className='flex items-center gap-2'>
                            <div className='flex gap-1'>
                              {[...Array(5)].map((_, index) => (
                                <FaStar
                                  key={index}
                                  className={`text-sm ${index < i.rating ? 'text-yellow-400' : 'text-gray-300'}`}
                                />
                              ))}
                            </div>
                            <p className='text-sm opacity-50'>({i.numReviews})</p>
                          </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <div>No products available</div>
            )
          )}
        </div>
      </section>
      
    </div>
  )
}

export default HomeScreen