import { BreadcrumbItem, Breadcrumbs, Button, Chip, Skeleton, Tab, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Tabs } from '@nextui-org/react'
import React from 'react'
import { useSelector } from 'react-redux'
import { format } from 'date-fns';
import { Link, useParams } from 'react-router-dom';
import { CiAlignLeft, CiDeliveryTruck, CiGrid42, CiSaveUp2, CiShop } from 'react-icons/ci';
import CountUp from 'react-countup';
import { HiOutlineBuildingStorefront, HiOutlineMapPin, HiOutlineShoppingCart, HiOutlineUserCircle, HiOutlineWrench } from 'react-icons/hi2';

const ProfileScreen = () => {

  const {page} = useParams()
  
  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin


  return (
    <div className='h-fit w-full flex flex-col'>
      <div className='h-fit w-full px-[25px] max-w-[1024px] mx-auto py-6'>
        <Breadcrumbs>
          <BreadcrumbItem className='font-medium capitalize'>Home</BreadcrumbItem>
          <BreadcrumbItem className='font-medium capitalize'>Dashboard</BreadcrumbItem>
          {
            page?
            <BreadcrumbItem className='font-medium capitalize'>{page}</BreadcrumbItem>:
            ''
          }
        </Breadcrumbs>
      </div> 
      <section className='h-fit w-full bg-white py-12'>
        <div className='h-fit w-full max-w-[1024px] mx-auto px-[15px] sm:px-[25px]'>
          <div className='w-full flex flex-col md:flex-row md:gap-4'>
            <div className='w-full grid grid-cols-1 md:grid-cols-4 gap-8'>
              <Tabs 
                classNames={{
                    tabList: "bg-transparent min-w-full md:min-w-full text-left rounded-[12px] px-0 mx-0 flex flex-col justify-center border-black/10 border-[1px] p-4 ",
                    cursor: "bg-blue-500 rounded-[6px] flex flex-col justify-center",
                    tab: "min-w-full md:min-w-full text-left flex items-center rounded-[4px] flex flex-col justify-center ",
                    tabContent: "group-data-[selected=true]:text-[white] min-w-full md:min-w-full h-8 rounded-[4px] flex flex-col justify-center"
                }}
                radius='4px'
                size='lg' aria-label="Options" className='min-w-full md:min-w-fit flex flex-col'>
                <Tab key="overview" title={
                  <Link to={'/dashboard/orderview'} className='h-fit w-full md:w-[200px] flex justify-start items-center gap-4'>
                    <HiOutlineUserCircle className='text-2xl'/>
                    <p>Overview</p>
                  </Link>
                } className='h-fit min-w-max col-span-3 '>
                    <div className='h-fit w-full m-0 p-0 md:-mt-3 pl-8'>
                      <div className='h-fit w-full m-0 p-0 border-[1px] border-[#F02E62]/10 rounded-[6px]'>
                          <div className='border-[1px] border-[#F02E62]/10 bg-[#F02E62] py-2 px-4 rounded-t-[6px]'>
                            <p className='text-base text-white'>Account Overview</p>
                          </div>
                          <div className='py-6 px-4'>
                            <div className='h-fit w-full py-2 flex gap-6 items-center'>
                              <div className='h-12 w-12 rounded-full border-[1px]'>

                              </div>

                              <div className='h-fit'>
                                <p className='text-base font-medium'>Jhon Doe</p>
                                <p className='text-sm opacity-50'>jhondoe@gmail.com</p>
                              </div>
                              <Button className='ml-auto' color='primary' radius='sm'>
                                Edit
                              </Button>
                            </div>
                          </div>
                      </div>
                    </div>
                </Tab>
                <Tab key="orders" title={
                  <Link to={'/dashboard/orders'} className='h-fit w-full md:w-[200px] flex justify-start items-center gap-4'>
                    <HiOutlineShoppingCart className='text-2xl'/>
                    <p>My Orders</p>
                  </Link>
                } className='h-fit min-w-max col-span-3 flex flex-col gap-4'>
                  <div className='h-fit w-full m-0 p-0 md:-mt-3 pl-8'>
                      <div className='h-fit w-full m-0 p-0 border-[1px] border-[#F02E62]/10 rounded-[6px]'>
                          <div className='border-[1px] border-[#F02E62]/10 bg-[#F02E62] py-2 px-4 rounded-t-[6px]'>
                            <p className='text-base text-white'>My Orders</p>
                          </div>
                          <div className='py-6 px-4'>
                          {
                            userInfo && userInfo.orders ?
                            userInfo.orders.map(i=>(
                              <div className='h-fit w-full flex grid-cols-4 gap-4 items-center py-4 bg-white border-y-1 border-black/5 px-8 justify-between' >
                                {/* <div className='h-10 w-10 flex flex-col gap-4'>
                                  <div className='h-10 w-10 rounded-[6px] overflow-hidden'>
                                    <img src={i.image?i.image.image:''} alt='' className='w-10 h-10 object-contain scale-125'/>
                                  </div>
                                </div> */}
                                <div className='w-full flex flex-col md:flex-row gap-4 ' >
                                  <div className='h-fit w-full grid grid-cols-5 items-center gap-2'>
                                    <div className='w-full flex items-center gap-2 text-sm font-medium'>
                                      <p className='text-sm font-bold'>#{i._id}</p>
                                    </div> 
                                    <Chip size='sm' color='success' variant='flat'>
                                      {i.isDelivered? 'Delivered' :'On the way'}
                                    </Chip>
                                    <p className='text-sm font-medium opacity-50'>3 items</p>
                                    <div className='font-medium text-sm opacity-50'>
                                      {format(new Date(i.createdAt), 'dd MMM yyyy')}
                                    </div>
                                    <div className='font-medium text-sm text-[#F02E62]'>
                                      Rs <CountUp className='font-medium' end={i.totalPrice} decimal={2} />.00
                                    </div>
                                  </div>

                                </div>

                                {/* <div className='h-fit w-fit overflow-hidden rounded-full flex items-center justify-center border-[2px] border-green-500 p-2'>
                                  <CiDeliveryTruck className='text-2xl' />
                                </div>
                                 */}
                              </div>
                            )): 
                            ''
                          }
                        
                          </div>
                      </div>
                    </div>
                  
                </Tab>
                {/* <Tab key="address" title={
                  <Link to={'/dashboard/address'} className='h-fit w-full md:w-[200px] flex justify-start items-center gap-4'>
                    <HiOutlineMapPin className='text-2xl'/>
                    <p>My address</p>
                  </Link>
                } className='h-fit min-w-max col-span-3 '>
                    
                </Tab>
                <Tab key="account" title={
                  <Link to={'/dashboard/account'} className='h-fit w-full md:w-[200px] flex justify-start items-center gap-4'>
                    <HiOutlineUserCircle className='text-2xl'/>
                    <p>My Account</p>
                  </Link>
                } className='h-fit min-w-max col-span-3 '>
                    
                </Tab> */}
                <Tab key="changePassword" title={
                  <Link to={'/dashboard/changePassword'} className='h-fit w-full md:w-[200px] flex justify-start items-center gap-4'>
                    <HiOutlineWrench className='text-2xl'/>
                    <p>Change Password</p>
                  </Link>
                } className='h-fit min-w-max col-span-3 '>
                    
                </Tab>
                {/* <Tab key="myAddress" title={
                  <div className='h-fit w-full md:w-[200px] flex justify-start items-center gap-4'>
                    <CiDeliveryTruck className='text-2xl'/>
                    <p>Orders</p>
                  </div>
                } className='h-fit min-w-full'>
                    
                </Tab> */}
                <Tab key="logout" title={
                  <div  className='h-fit w-full md:w-[200px] flex justify-start items-center gap-4'>
                    <CiSaveUp2 className='text-2xl'/>
                    <p>Logout</p>
                  </div>
                } className='h-fit min-w-full'>
                    
                </Tab>
              </Tabs>
            </div>
            {/* <div className='h-fit w-full gap-8 items-start'>
              <div className='w-full h-[400px]'>
                <Table aria-label="Example table with dynamic content" className='gap-2 flex flex-col'>
                  <TableHeader >
                    <TableColumn className='font-bold opacity-50 text-base' >Order</TableColumn>
                    <TableColumn className='font-bold opacity-50 text-base' >Date</TableColumn>
                    <TableColumn className='font-bold opacity-50 text-base' >Payment</TableColumn>
                    <TableColumn className='font-bold opacity-50 text-base' >Total</TableColumn>
                    <TableColumn className='font-bold opacity-50 text-base' >Delivery</TableColumn>
                    <TableColumn className='font-bold opacity-50 text-base' >Items</TableColumn>
                  </TableHeader>
                  <TableBody >
                    
                      {
                        userInfo && userInfo.orders ?
                        userInfo.orders.map(i=>(
                          <TableRow key={i._id} className=''>
                            <TableCell className='text-xs font-bold'>
                              <Link to={`/profile/order/${i._id}`} >
                                #{i._id}
                              </Link>
                            </TableCell>
                            <TableCell className='text-xs opacity-75'>
                              <Link to={`/profile/order/${i._id}`} >
                                {format(new Date(i.createdAt), 'dd MMMM yyyy')}
                              </Link>
                            </TableCell>
                            <TableCell className='text-xs opacity-75'>{i.paymentMethod === 'COD'?
                              <Button variant='bordered' size='sm' color="danger" endContent={
                                <div className='h-[4px] w-[4px] rounded-full bg-red-600'></div>
                              }>
                                Panding
                              </Button>    
                            :
                              <Button variant='bordered' size='sm' color="success" endContent={
                                <div className='h-[4px] w-[4px] rounded-full bg-green-600'></div>
                              }>
                                Success
                              </Button>
                            }</TableCell>
                            <TableCell className='text-xs opacity-75'>
                              <Link to={`/profile/order/${i._id}`} >
                                {i.totalPrice}
                              </Link>
                            </TableCell>
                            <TableCell className='text-xs opacity-75'>
                              <Link to={`/profile/order/${i._id}`} >
                                {i.isDelivered? 'Delivered' :'On the way'}
                              </Link>
                            </TableCell>
                            <TableCell className='text-xs opacity-75'>
                              <Link to={`/profile/order/${i._id}`} >
                                {i.orderItems.length} items
                              </Link>
                            </TableCell>

                          </TableRow>
                        )): 
                        <TableRow>
                          <TableCell>gi</TableCell>
                          <TableCell>gi</TableCell>
                          <TableCell>gi</TableCell>
                          <TableCell>gi</TableCell>
                          <TableCell>gi</TableCell>
                          <TableCell>gi</TableCell>
                        </TableRow>
                      }

                  </TableBody>
                </Table>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  )
}

export default ProfileScreen