import React, { useEffect, useState } from 'react'
import {Input} from "@nextui-org/input";
import { FaEye, FaEyeSlash  } from "react-icons/fa6";
import {Textarea} from "@nextui-org/input";
import {Button, Progress, Spinner} from "@nextui-org/react";
import { IoMdMail, IoMdLock  } from "react-icons/io";
import { RiLoginCircleFill } from "react-icons/ri";
import {Checkbox} from "@nextui-org/checkbox";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/Logo.jpg'
import { GiCancel } from "react-icons/gi";
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../actions/userActions';
import Alert from '../componets/Alert';
import { USER_REGISTER_RESET } from '../constants/userConstants';


const RegisterScreen = () => {
    const [isPasswdVisible, setIsPasswdVisible] = React.useState(false);
    const toggleVisibility = () => setIsPasswdVisible(!isPasswdVisible);

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const location = useLocation()
    const history = useNavigate()
    const dispatch = useDispatch()

    const userRegister = useSelector(state => state.userRegister)
    const { error, loading, user, success } = userRegister

    useEffect(() => {
      window.scroll(0,0);
    }, [location]);

    useEffect(() => {
      if (success) {
        history('/login')
        dispatch({ type: USER_REGISTER_RESET })
      }
    }, [success])

    const submitHandler = () => {
      dispatch(register(email, password, confirmPassword))
      window.scroll(0,0)
    }
  return (
    <div className='h-fit md:min-h-screen flex items-center w-full'>      
      <section className='h-fit min-h-fit md:py-6 flex items-center justify-center w-full '>
        <div className='h-fit w-full  max-w-[1100px] mx-auto px-6 flex flex-col md:flex-row items-start md:justify-around gap-6 md:gap-0'>
          <div className='bg-white gap-4 shadow-[0px_4px_25px_rgba(0,0,0,0.05)] rounded-[8px] flex flex-col w-full sm:w-fit relative overflow-hidden mx-auto'>
            {
              loading?
              <Progress
                size="sm"
                isIndeterminate
                aria-label="Loading..."
                className="w-full absolute top-0 z-30"
              />:
              ''
            }
            {
              error?
              <Alert titile={'Error'} content={error} varient={'red'} />:
              ''
            }
            <div className={
              loading?
              'bg-white gap-4 px-6 py-8 rounded-[8px] flex flex-col w-full sm:w-fit relative opacity-50 pointer-events-none duration-200':
              'bg-white gap-4 px-6 py-8 rounded-[8px] flex flex-col w-full sm:w-fit relative opacity-100 duration-200'
            }>
              <Link to={'/'} className=''>
                <GiCancel className='absolute top-6 right-6 opacity-30' />
              </Link>
              <div className='flex flex-row items-center justify-between pb-4'>
                <img src={logo} alt='' className='h-16 w-fit object-contain border-[1px] border-black/10 rounded-[6px]'/>
                <div className='flex flex-col items-end'>
                  <p className='text-lg font-semibold text-right'>Sign up!</p>
                  <p className='text-xs text-gray-400 text-right'>Create your account to continue</p>
                </div>
              </div>
              <Input 
                labelPlacement="outside"
                placeholder="you@example.com"
                type="email" 
                label="Email" 
                className='w-full sm:w-[300px] rounded-[6px]' 
                radius='sm' 
                size='md'
                isInvalid={
                  error?
                  true:
                  false
                }
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                startContent={
                  <IoMdMail  className="text-xl text-default-400 pointer-events-none flex-shrink-0" />
                }
                />
              <Input 
                labelPlacement="outside"
                placeholder="**********"
                type={isPasswdVisible ? "text" : "password"}
                label="Password" 
                className='w-full sm:w-[300px] rounded-[6px]' 
                radius='sm' 
                size='md'
                isInvalid={
                  error?
                  true:
                  false
                }
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                startContent={
                  <IoMdLock  className="text-xl text-default-400 pointer-events-none flex-shrink-0" />
                }
                endContent={
                  <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                    {isPasswdVisible ? (
                      < FaEyeSlash className="text-xl text-default-400 pointer-events-none" />
                    ) : (
                      <FaEye  className="text-xl text-default-400 pointer-events-none" />
                    )}
                  </button>
                }
              />
              <Input 
                labelPlacement="outside"
                placeholder="**********"
                type={isPasswdVisible ? "text" : "password"}
                label="Confirm Password" 
                className='w-full sm:w-[300px] rounded-[6px]' 
                radius='sm' 
                size='md'
                isInvalid={
                  error?
                  true:
                  false
                }
                value={confirmPassword} 
                onChange={(e) => setConfirmPassword(e.target.value)} 
                startContent={
                  <IoMdLock  className="text-xl text-default-400 pointer-events-none flex-shrink-0" />
                }
              />
              <div className='flex max-w-full gap-2 items-center justify-between'>
                <Checkbox size="sm" defaultSelected className='text-xs'><p className='text-xs'>Terms and Privacy Policy</p></Checkbox>
                <Link to={'/create/activation/link'} className='text-xs text-blue-600 font-medium ml-auto'>Get Activation Link</Link>
              </div>
              <Button 
                onClick={submitHandler} endContent={
                  loading?
                  <Spinner size='sm' color='default' className='text-white'/>:
                  ''
                }
                className="rounded-[8px] font-medium bg-[#F02E62] text-black">
                Create
              </Button>
              <p className='text-xs'>I already have an account? <Link to={"/register"} className='text-[#F02E62]'>Sign In</Link></p>
            </div>
          </div>
          
        </div>
      </section>
    </div>    
  )
}

export default RegisterScreen