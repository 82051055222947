import React from 'react'
import { useGoogleLogin } from '@react-oauth/google';
import GoogleButton from 'react-google-button'
import { GoogleLogin } from '@react-oauth/google';

import { googleLogin } from '../actions/userActions';
import { useDispatch } from 'react-redux';
import { Button } from '@nextui-org/react';

const Google = () => {
    // const location = useLocation()
    // const history = useNavigate()
    const dispatch = useDispatch()

    const handleSuccess = async (codeResponse) => {
        console.log("Authorization Code Response:", codeResponse);
        dispatch(googleLogin(codeResponse.code));
        // try {
        //     // Send the authorization code to the backend
        //     const response = await axios.post('http://localhost:8000/login-with-google/', {
        //         code: codeResponse.code, // Extracted authorization code
        //     });

        //     console.log("Login Successful, Backend Response:", response.data);
        // } catch (error) {
        //     console.error("Login Failed, Backend Error:", error.response?.data || error.message);
        // }
    };

    // Configure Google Login
    const login = useGoogleLogin({
        onSuccess: handleSuccess, // Handle success
        flow: 'auth-code',       // Use authorization code flow
    });
  return (
    <Button className='min-w-full rounded-[4px] bg-transparent border-black/10 border-[1px]' onClick={login} >
        <img src='https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png' alt='' className='h-[30px] w-[30px] object-cover' />
        <p>Sign up with Goolge</p>
    </Button>
  )
}

export default Google